const _id = 'GoldApple.kz'
const autoInit = false;

import { Mindbox } from './mindbox.class'

function getMindbox() {
    const mindbox = new Mindbox()

    return {
        create(id) {
            return mindbox.create(id)
        },

        async(payload) {
            return mindbox.async(payload)
        },

        sync(payload) {
            return mindbox.sync(payload)
        },
    }
}

export default function (context, inject) {
    const runtimeConfig = (context.$config && context.$config.mindbox) || {}

    const id = runtimeConfig.id || _id

    const $mindbox = getMindbox()

    if (autoInit && id) {
        $mindbox.create(id)
    }

    inject('mindbox', $mindbox)
}
